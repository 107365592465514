var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('search-box',{attrs:{"disabled":_vm.selecionouClienteAvulso,"label":"Cliente","popup-label":"Selecione um Cliente","id":_vm.value.idCliente,"nome":_vm.value.nomeCliente,"value":_vm.cliente,"loader-fn":_vm.loaderClientes,"item-key":"id","item-text":"nome"},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCliente", $event)},"update:nome":function($event){return _vm.$set(_vm.value, "nomeCliente", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Cliente encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"disabled":parseInt(_vm.value.idCliente) > 0 && !_vm.selecionouClienteAvulso,"label":"* Cliente Avulso","dense":"","type":"text"},model:{value:(_vm.value.clienteAvulso),callback:function ($$v) {_vm.$set(_vm.value, "clienteAvulso", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.clienteAvulso"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('search-box',{attrs:{"hidden-clear":true,"emit-all-properties":true,"label":"* Competição","popup-label":"Selecione uma Competição Ativa","id":_vm.value.idCompCompeticao,"descricao":_vm.value.descInscricaoCompeticao,"idCompEvento":_vm.value.idCompEvento,"flgTipoComp":_vm.value.flgTipoComp,"flgValorUnico":_vm.value.flgValorUnico,"valorInscricao":_vm.value.valorInscricao,"unicas":_vm.value.unicas,"value":_vm.competicao,"loader-fn":_vm.loaderCompeticoesAtivas,"item-key":"id","item-text":"descricao","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCompCompeticao", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descInscricaoCompeticao", $event)},"update:idCompEvento":function($event){return _vm.$set(_vm.value, "idCompEvento", $event)},"update:id-comp-evento":function($event){return _vm.$set(_vm.value, "idCompEvento", $event)},"update:flgTipoComp":function($event){return _vm.$set(_vm.value, "flgTipoComp", $event)},"update:flg-tipo-comp":function($event){return _vm.$set(_vm.value, "flgTipoComp", $event)},"update:flgValorUnico":function($event){return _vm.$set(_vm.value, "flgValorUnico", $event)},"update:flg-valor-unico":function($event){return _vm.$set(_vm.value, "flgValorUnico", $event)},"update:valorInscricao":function($event){return _vm.$set(_vm.value, "valorInscricao", $event)},"update:valor-inscricao":function($event){return _vm.$set(_vm.value, "valorInscricao", $event)},"update:unicas":function($event){return _vm.$set(_vm.value, "unicas", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhuma Competição Ativa encontrada ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":"* Valor Insc.","prefix":"R$","rules":[
            this.$validators.number.required,
            function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 0); }
        ],"type":"number"},model:{value:(_vm.value.valorInscricao),callback:function ($$v) {_vm.$set(_vm.value, "valorInscricao", $$v)},expression:"value.valorInscricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('lookup-dominio',{attrs:{"label":"* Status","value":_vm.statusAtivo,"type":_vm.lookups.status,"hidden-clear":false},model:{value:(_vm.value.status),callback:function ($$v) {_vm.$set(_vm.value, "status", $$v)},expression:"value.status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.value.flgTipoComp === 'UN')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('strong',[_vm._v("Opções disponíveis")]),_vm._v(" "),_c('v-icon',{on:{"click":function($event){return _vm.limpaProva()}}},[_vm._v("mdi-reload")]),_c('v-radio-group',{attrs:{"dense":true,"column":""},on:{"change":_vm.selecionaOpcao},model:{value:(_vm.opcaoInscricao),callback:function ($$v) {_vm.opcaoInscricao=$$v},expression:"opcaoInscricao"}},_vm._l((this.value.unicas),function(option){return _c('v-radio',{key:option.id,attrs:{"color":"red","label":option.descricao,"value":option.id}})}),1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }